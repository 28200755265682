import { useMutation, useQuery } from '@apollo/client';
import {
	RecentSearchesQueryQuery,
	SearchSuggestionsQueryQuery,
	SuggestedUsersQueryQuery,
	TopColorsQueryQuery,
	TopSearchQueriesQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import DeleteRecentSearchMutation from '@apps/www/src/www/queries/DeleteRecentSearchMutation';
import RecentSearchesQuery from '@apps/www/src/www/queries/RecentSearchesQuery';
import SearchSuggestionsQuery from '@apps/www/src/www/queries/SearchSuggestionsQuery';
import SuggestedUsersQuery from '@apps/www/src/www/queries/SuggestedUsersQuery';
import TopColorsQuery from '@apps/www/src/www/queries/TopColorsQuery';
import TopSearchQueriesQuery from '@apps/www/src/www/queries/TopSearchQueriesQuery';
import SVA from '@pkgs/shared-client/components/SVA';
import SVColorBalls from '@pkgs/shared-client/components/SVColorBalls';
import SVColorPickerOverlayContent from '@pkgs/shared-client/components/SVColorPickerOverlayContent';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVKeyboardKey from '@pkgs/shared-client/components/SVKeyboardKey';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVProBadge from '@pkgs/shared-client/components/SVProBadge';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconCloseSearchSVG from '@pkgs/shared-client/img/icon-close-search-inlined.svg';
import IconDeleteRecentSearchSVG from '@pkgs/shared-client/img/icon-delete-recent-search-inlined.svg';
import IconNavbarSearchSVG from '@pkgs/shared-client/img/navbar-search-icon-inlined.svg';
import ImagePalletColor from '@pkgs/shared-client/img/search-palet-color.png';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';

const MIN_QUERY_LENGTH = 2 as const;
export const SUPPORTED_TYPES = ['items', 'users'] as const;
const SCROLL_TOLERANCE = 40 as const;

const _ScrollEndPadding = ({ className }: { className?: string }) => (
	<div className={twMerge('min-w-8 flex-shrink-0', className)} />
);

type InputProps = {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	isOnFocus: boolean;
	onFocus?: () => void;
	onClear: (e: React.UIEvent) => void;
	onSelectSuggestion?: (suggestion: string) => void;
	forceBlur?: () => void;
};

type UserSuggestion = ArrayElement<SearchSuggestionsQueryQuery['searchSuggestions']['users']> & {
	isUser: true;
};

const PLACEHOLDER_CHANGE_INTERVAL = 3500; // 3.5s

const PLACEHOLDER_PHRASES = [
	'"design inspiration"',
	'"branding ideas"',
	'"typography"',
	'"#3730A3"',
];

const _AnimatedPlaceholderText = ({ isOnFocus }: { isOnFocus: boolean }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const lastIndex = usePrevious(currentIndex);
	const [isMdScreen, setIsMdScreen] = useState(false);

	// Add effect to detect screen size
	useEffect(() => {
		const checkScreenSize = () => {
			setIsMdScreen(window.innerWidth <= 768);
		};

		checkScreenSize();
		window.addEventListener('resize', checkScreenSize);
		return () => window.removeEventListener('resize', checkScreenSize);
	}, []);

	// Filter phrases based on screen size
	const displayPhrases = isMdScreen
		? PLACEHOLDER_PHRASES.filter((phrase) => phrase !== '"design inspiration"')
		: PLACEHOLDER_PHRASES;

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % displayPhrases.length);
		}, PLACEHOLDER_CHANGE_INTERVAL);

		return () => clearInterval(timer);
	}, [currentIndex, displayPhrases.length]);

	return (
		<div
			className={clsx(
				'absolute left-0 top-0 flex h-full w-full items-center pl-9 text-gray-500',
				isOnFocus ? '-md:hidden' : '',
			)}
		>
			<span>Search for&nbsp;</span>
			<span className="relative h-[28px] flex-1 overflow-visible">
				<span className="absolute h-full w-full overflow-hidden">
					{displayPhrases.map((phrase, index) => (
						<span
							key={index}
							className={clsx(
								'absolute flex h-full w-full items-center',
								index === currentIndex
									? 'translate-y-0'
									: index === lastIndex
									? 'translate-y-[-28px]'
									: 'translate-y-[28px]',

								// Only animate current and previous phrase
								index === currentIndex || index === lastIndex
									? 'transition-all duration-[0.65s] ease-in-out'
									: '',
							)}
						>
							{phrase}
						</span>
					))}
				</span>
			</span>
		</div>
	);
};

const _Input = ({
	value,
	onChange,
	onKeyDown,
	isOnFocus,
	onClear,
	onFocus,
	onSelectSuggestion,
	onColorPickerOpen,
	isColorPickerOpen,
	...props
}: InputProps & { onColorPickerOpen?: (isOpen: boolean) => void; isColorPickerOpen?: boolean }) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const isHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value);

	useEffect(() => {
		if (isOnFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isOnFocus]);

	const handleKeyDown = useEventCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			onKeyDown(e);
			inputRef.current?.blur();
			return;
		}
		onKeyDown(e);
	});

	const handleFocus = useEventCallback(() => {
		onFocus?.();
	});

	const handleInputClick = useEventCallback(() => {
		if (isColorPickerOpen) {
			onColorPickerOpen?.(false);
		}
		onFocus?.();
	});

	return (
		<div
			className={twMerge(
				'duration-slide text-primary border-separator bg-background group/search -md:max-w-[38vw] -lg:max-w-[35vw] relative z-30 flex h-[44px] w-[500px] max-w-[30vw] cursor-text space-x-2 rounded-full border-[1.5px] px-3 py-2.5 transition-all ease-in-out hover:border-gray-600',
				isOnFocus ? 'border-gray-500 hover:border-gray-500' : '',
			)}
			{...props}
		>
			<div className="duration-slide flex w-6 items-center justify-center text-gray-500 transition-all ease-in-out">
				<IconNavbarSearchSVG className="h-6 w-6 min-w-6" />
			</div>
			{value.length === 0 ? <_AnimatedPlaceholderText isOnFocus={isOnFocus} /> : null}
			<div className="relative flex w-full items-center justify-center">
				<input
					type="text"
					value={value}
					onChange={onChange}
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					onClick={handleInputClick}
					placeholder=""
					className={clsx(
						'search-input-field w-full border-none bg-transparent p-0 text-xl outline-none focus:outline-none focus:ring-0',
						isHexColor ? 'opacity-0' : '',
					)}
					ref={inputRef}
					required
				/>
				{isHexColor && (
					<div className="pointer-events-none absolute inset-0 flex items-center">
						<span
							className="rounded-lg p-0.5 text-xl"
							style={{ backgroundColor: value, color: getContrastColor(value) }}
						>
							{value}
						</span>
					</div>
				)}
			</div>
			<div
				className={clsx(
					'duration-over group mr-2 flex cursor-pointer items-center justify-center rounded-full transition-opacity ease-in-out hover:bg-gray-800',
					isOnFocus || value.length > 0 ? 'opacity-100' : 'pointer-events-none opacity-0',
				)}
			>
				<SVIconButton
					src={IconCloseSearchSVG}
					onClick={(e) => onClear(e)}
					className="z-10 h-5 w-5 text-gray-600"
					disableHover
				/>
				<div className="absolute flex h-8 w-8 items-center justify-center rounded-full transition-all ease-in-out group-hover:bg-gray-900" />
			</div>
			<_ColorPickerButton
				isOnFocus={isOnFocus}
				value={value}
				onOpenChange={onColorPickerOpen}
			/>
		</div>
	);
};

// Helper function to determine text color based on background color
const getContrastColor = (hexColor: string): string => {
	// Remove the # if it exists
	const hex = hexColor.replace('#', '');

	// Convert to RGB
	let r = 0,
		g = 0,
		b = 0;

	if (hex.length === 3) {
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	} else if (hex.length === 6) {
		r = parseInt(hex.substring(0, 2), 16);
		g = parseInt(hex.substring(2, 4), 16);
		b = parseInt(hex.substring(4, 6), 16);
	}

	// Calculate luminance
	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

	// Return black or white based on luminance
	return luminance > 0.5 ? '#000000' : '#ffffff';
};

const _ColorPickerButton = ({
	isOnFocus,
	value,
	onOpenChange,
}: {
	isOnFocus: boolean;
	value: string;
	onOpenChange?: (isOpen: boolean) => void;
}) => {
	const router = useRouter();

	const handleColorClick = useEventCallback((color: string) => {
		router.push(`/search?q=${encodeURIComponent(color)}`);
		onOpenChange?.(false);
	});

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			positionStrategy={SVDropdown.POSITION_STRATEGIES.DYNAMIC}
			contentOffset="h-[20px]"
			onOpen={() => onOpenChange?.(true)}
			onClose={() => onOpenChange?.(false)}
			hideCloseButton
			renderTrigger={({ isOpen, ...props }) => {
				if (!isOpen) {
					onOpenChange?.(false);
				}

				return (
					<button
						className={clsx(
							'duration-over group relative flex min-w-5 cursor-pointer items-center justify-center',
							isOnFocus || value.length > 0 ? 'opacity-100' : 'opacity-0',
							'z-50 group-hover/search:opacity-100',
						)}
						{...props}
					>
						<div className="z-10 flex h-5 w-5 items-center justify-center rounded-full bg-gray-200">
							<SVImage
								src={ImagePalletColor}
								alt="Search by color"
								className="h-5 w-5"
							/>
						</div>
						<div
							className={twMerge(
								'absolute flex h-8 w-8 items-center justify-center rounded-full transition-all ease-in-out group-hover:bg-gray-900',
								isOpen && 'bg-gray-900',
							)}
						/>
					</button>
				);
			}}
			renderContent={() => (
				<div className="h-[230px] w-[300px]">
					<SVColorPickerOverlayContent
						onColorClick={handleColorClick}
						className="h-full w-full"
					/>
				</div>
			)}
		/>
	);
};

const _SectionTitle = ({ title, className }: { title: string; className?: string }) => (
	<div
		className={clsx(
			'flex items-center pb-3 text-xl text-gray-600 transition-opacity duration-75 ease-in',
			className,
		)}
	>
		{title}
	</div>
);

const _RecentSearchItem = ({
	search,
	handleClear,
}: {
	search: RecentSearchesQueryQuery['recentSearches'][number];
	handleClear: (id: RecentSearchesQueryQuery['recentSearches'][number]['_id']) => void;
}) => {
	const isHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(search.query);

	return (
		<SVLink
			className="duration-slide group mr-0.5 flex items-center rounded-full border border-gray-700 px-3 py-2 text-base transition-all ease-in-out hover:bg-gray-800"
			to={`/search?q=${encodeURIComponent(search.query)}`}
			title={`Search for ${search.query}`}
		>
			<div className="max-w-50 flex items-center space-x-2">
				{isHexColor && (
					<div
						className="h-5 w-5 min-w-5 rounded-full border border-gray-700"
						style={{ backgroundColor: search.query }}
					/>
				)}
				<div className="w-full truncate text-gray-400">{search.query}</div>
				<SVIconButton
					src={IconDeleteRecentSearchSVG}
					onClick={(e) => {
						handleClear(search._id);
						e.preventDefault();
						e.stopPropagation();
					}}
					className="text-gray-600 hover:text-gray-300"
					iconClassName="h-4 w-4 min-w-4 min-h-4"
					disableHover
					title="Delete recent search"
				/>
			</div>
		</SVLink>
	);
};

const _PopularSearchItem = ({
	search,
}: {
	search: TopSearchQueriesQueryQuery['topSearchQueries'][number];
}) => (
	<SVLink
		className="group flex h-14 w-[184px] items-center justify-start space-x-2 rounded-full border border-gray-600 px-2 py-2 transition-all ease-in-out hover:bg-gray-800"
		to={`/search?q=${encodeURIComponent(search.query)}`}
		title={`Search for ${search.query}`}
	>
		<div className="relative h-10 w-10 overflow-hidden rounded-full border border-transparent transition-all ease-in-out group-hover:border-gray-600">
			<div className="absolute inset-0 overflow-hidden">
				<div
					className="duration-over absolute inset-0 bg-cover bg-center bg-no-repeat opacity-100 transition-all ease-out"
					style={{
						backgroundColor: search.asset.colors[0]?.color,
						backgroundImage: `url('${search.asset.image.thumbnail}')`,
					}}
				/>
			</div>
		</div>

		<button className="w-fit truncate text-base text-gray-300">{search.query}</button>
	</SVLink>
);

const _TopUsersItem = ({ user }: { user: SuggestedUsersQueryQuery['suggestedUsers'][number] }) => (
	<SVA
		Component={SVLink}
		key={user._id}
		className="flex w-full min-w-[200px] items-center space-x-2 rounded-xl border border-transparent bg-gray-900 p-3 transition-all ease-in-out hover:border-gray-600 hover:bg-gray-800"
		to={user.url}
		title={`View ${user.name}'s profile`}
	>
		<SVImage src={user.avatarURL} alt={user.name} className="h-8 w-8 rounded-full" />
		<div className="flex min-w-0 flex-1 flex-col">
			<div className="flex items-center">
				<div className="truncate whitespace-nowrap text-base text-gray-300">
					{user.name}
				</div>
				<SVProBadge className="text-primary ml-2" />
			</div>
			<div className="truncate whitespace-nowrap text-sm text-gray-600">@{user.username}</div>
		</div>
	</SVA>
);

const SearchSuggestionSkeleton = () => {
	return (
		<div className="flex w-full animate-pulse items-center rounded-xl px-3 py-2">
			<div className="mr-4 h-10 w-10 rounded-full bg-gray-800"></div>
			<div className="h-3 w-2/4 rounded-full bg-gray-800"></div>
		</div>
	);
};

const _SearchContent = ({
	recentSearches,
	popularSearches,
	isVisible,
	searchQuery,
	selectedIndex,
	onSelectSuggestion,
	onItemHover,
	hoveredItemIndex,
	searchSuggestions,
	topColors,
	topUsers,
	isLoadingSuggestions,
}: {
	recentSearches: RecentSearchesQueryQuery['recentSearches'];
	popularSearches: TopSearchQueriesQueryQuery['topSearchQueries'];
	isVisible: boolean;
	searchQuery: string;
	selectedIndex: number;
	onSelectSuggestion: (suggestion: string | UserSuggestion) => void;
	onItemHover: (index: number | null) => void;
	hoveredItemIndex: number | null;
	searchSuggestions?: SearchSuggestionsQueryQuery['searchSuggestions'];
	topColors?: TopColorsQueryQuery['topColors'];
	topUsers?: SuggestedUsersQueryQuery['suggestedUsers'];
	isLoadingSuggestions?: boolean;
}) => {
	const [deleteRecentSearch] = useMutation(DeleteRecentSearchMutation, {
		update: (cache, { data }) => {
			if (!data?.deleteRecentSearch) return;

			const deletedID = data.deleteRecentSearch._id;

			// Update cache to remove the deleted search
			cache.modify({
				fields: {
					recentSearches: (existingSearches = []) => {
						return existingSearches.filter(
							(search: { __ref: string }) => !search.__ref.includes(deletedID),
						);
					},
				},
			});
		},
	});
	const contentRef = useRef<HTMLDivElement>(null);
	const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);

	const getTextSuggestions = () => {
		if (searchQuery.trim().length === 0) return [];

		if (!searchSuggestions) {
			return searchQuery.trim().length >= MIN_QUERY_LENGTH ? [searchQuery.trim()] : [];
		}

		const suggestions = searchSuggestions.queries.map((suggestion) => suggestion.query);

		if (!suggestions.includes(searchQuery.trim())) {
			return [searchQuery.trim(), ...suggestions];
		}

		return suggestions;
	};

	// Get user suggestions
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const userSuggestions: UserSuggestion[] =
		searchQuery.trim().length > 0 && searchSuggestions
			? searchSuggestions.users.map((user) => ({
					...user,
					isUser: true,
			  }))
			: [];

	const textSuggestions = getTextSuggestions();

	// Calculate skeletons count for consistent UI
	const textSkeletonCount = 4;

	const isShowingSuggestions = searchQuery.trim().length > 0;
	const hasNonEmptyQuery = searchQuery.trim().length > 0;

	useEffect(() => {
		if (contentRef.current && isVisible) {
			const newHeight = contentRef.current.scrollHeight;

			// Add a small buffer, but not too much
			const extraPadding = isShowingSuggestions ? 4 : 0;

			setContentHeight(newHeight + extraPadding);

			// Quick recalculation without additional padding after render
			setTimeout(() => {
				if (contentRef.current) {
					setContentHeight(contentRef.current.scrollHeight + extraPadding);
				}
			}, 50);
		}
	}, [
		isVisible,
		searchQuery,
		recentSearches,
		popularSearches,
		searchSuggestions,
		userSuggestions,
		isShowingSuggestions,
		isLoadingSuggestions,
	]);

	const handleClearRecentSearches = useEventCallback(async (id: string) => {
		// Execute mutation
		await deleteRecentSearch({
			variables: {
				input: {
					searchID: id,
				},
			},
			optimisticResponse: {
				deleteRecentSearch: {
					__typename: 'RecentSearch',
					_id: id,
					query: '',
					deleted: true,
				},
			},
		});
	});

	const hasContentToShow =
		recentSearches.length > 0 ||
		popularSearches?.length > 0 ||
		(topColors?.length ?? 0) > 0 ||
		(topUsers?.length ?? 0) > 0;

	return (
		<div
			className={twMerge(
				'duration-slide absolute left-1/2 top-full z-20 mt-2 -translate-x-1/2 overflow-hidden rounded-[24px] border border-transparent bg-gray-950 transition-all ease-in-out ',
				isVisible
					? 'translate-y-0 transform opacity-100'
					: 'pointer-events-none -translate-y-2 transform opacity-0',
				contentHeight !== undefined && contentHeight > 0 && 'border-gray-900',
				isShowingSuggestions
					? '-md:max-w-[38vw] -lg:max-w-[35vw] w-[500px] max-w-[30vw]'
					: '-md:max-w-[80vw] w-[840px] max-w-[50vw] lg:max-w-[70vw] ',
			)}
			style={{
				height: contentHeight !== undefined ? `${contentHeight}px` : 'auto',
			}}
		>
			<div
				ref={contentRef}
				className={`duration-slide transition-opacity ease-in-out ${
					isVisible ? 'opacity-100' : 'opacity-0'
				}`}
			>
				{isShowingSuggestions ? (
					<div className="duration-slide transition-opacity ease-in-out">
						<div className="w-full space-y-0 p-4">
							{/* Always show current query as first suggestion */}
							{hasNonEmptyQuery && (
								<SearchSuggestionItem
									key={`current-${searchQuery}`}
									suggestion={searchQuery.trim()}
									searchQuery={searchQuery}
									isSelected={0 === selectedIndex && hoveredItemIndex === null}
									onClick={() => onSelectSuggestion(searchQuery.trim())}
									onMouseEnter={() => onItemHover(0)}
									onMouseLeave={() => onItemHover(null)}
									isHovered={hoveredItemIndex === 0}
								/>
							)}

							{/* Show skeletons when loading for any non-empty query */}
							{isLoadingSuggestions && hasNonEmptyQuery ? (
								<>
									{Array.from({ length: textSkeletonCount }).map((_, index) => (
										<SearchSuggestionSkeleton key={`skeleton-text-${index}`} />
									))}
								</>
							) : (
								<>
									{textSuggestions.slice(1).map((suggestion, index) => {
										const globalIndex = index + 1;
										return (
											<SearchSuggestionItem
												key={suggestion}
												suggestion={suggestion}
												searchQuery={searchQuery}
												isSelected={
													globalIndex === selectedIndex &&
													hoveredItemIndex === null
												}
												onClick={() => onSelectSuggestion(suggestion)}
												onMouseEnter={() => onItemHover(globalIndex)}
												onMouseLeave={() => onItemHover(null)}
												isHovered={hoveredItemIndex === globalIndex}
											/>
										);
									})}

									{userSuggestions.map((suggestion, index) => {
										const globalIndex = index + textSuggestions.length;
										return (
											<SearchSuggestionUser
												key={suggestion.username}
												suggestion={suggestion}
												isSelected={
													globalIndex === selectedIndex &&
													hoveredItemIndex === null
												}
												onClick={() => onSelectSuggestion(suggestion)}
												onMouseEnter={() => onItemHover(globalIndex)}
												onMouseLeave={() => onItemHover(null)}
												isHovered={hoveredItemIndex === globalIndex}
											/>
										);
									})}
								</>
							)}
						</div>
					</div>
				) : (
					<div
						className={clsx(
							hasContentToShow ? 'py-10 pl-8' : '',
							'duration-slide flex flex-col space-y-[60px] transition-opacity ease-in-out',
						)}
					>
						{recentSearches.length > 0 && (
							<div>
								<_SectionTitle title="Recents" />
								<div className="no-scrollbar flex gap-1 overflow-x-auto">
									{recentSearches.map((search) => (
										<_RecentSearchItem
											key={search._id}
											search={search}
											handleClear={handleClearRecentSearches}
										/>
									))}
									<_ScrollEndPadding className="-ml-1" />
								</div>
							</div>
						)}
						{popularSearches?.length > 0 && (
							<div>
								<_SectionTitle title="Popular searches" />
								<div className="no-scrollbar flex h-[138px] flex-col overflow-x-auto">
									<div className="mb-3 flex min-w-max gap-3">
										{popularSearches
											.slice(0, Math.ceil(popularSearches.length / 2))
											.map((popularSearch) => (
												<_PopularSearchItem
													key={popularSearch._id}
													search={popularSearch}
												/>
											))}
										<_ScrollEndPadding className="-ml-3" />
									</div>
									<div className="flex min-w-max gap-3">
										{popularSearches
											.slice(Math.ceil(popularSearches.length / 2))
											.map((popularSearch) => (
												<_PopularSearchItem
													key={popularSearch._id}
													search={popularSearch}
												/>
											))}
										<_ScrollEndPadding className="-ml-3" />
									</div>
								</div>
							</div>
						)}

						{(topUsers?.length ?? 0) > 0 && (
							<div>
								<_SectionTitle title="Trending users" />
								<div className="no-scrollbar flex gap-4 overflow-x-auto">
									{topUsers?.map((user) => (
										<_TopUsersItem key={user._id} user={user} />
									))}
									<_ScrollEndPadding className="-ml-6" />
								</div>
							</div>
						)}

						{(topColors?.length ?? 0) > 0 && (
							<div>
								<_SectionTitle title="Trending colors" />
								<div className="no-scrollbar flex overflow-x-auto">
									<div className="flex min-w-max">
										<SVColorBalls
											colors={topColors || []}
											className="flex min-w-[calc(100%+100px)] space-x-2"
										/>
									</div>
									<_ScrollEndPadding />
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const SearchSuggestionItem = ({
	suggestion,
	searchQuery,
	isSelected,
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHovered,
}: {
	suggestion: string;
	searchQuery: string;
	isSelected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	isHovered?: boolean;
}) => {
	const query = searchQuery.toLowerCase();
	const suggestionLower = suggestion.toLowerCase();
	let index = -1;
	let matchLength = 0;
	const isHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(suggestion);

	if (query.endsWith(' ')) {
		const queryWithoutTrailingSpace = query.trimEnd();
		if (suggestionLower.startsWith(queryWithoutTrailingSpace + ' ')) {
			index = 0;
			matchLength = queryWithoutTrailingSpace.length + 1;
		}
	} else {
		index = suggestionLower.indexOf(query);
		matchLength = query.length;
	}

	// Apply styles based on selection or hover
	const isHighlighted = isSelected || isHovered;

	return (
		<SVA
			Component={SVLink}
			key={suggestion}
			className={clsx(
				'group flex w-full items-center rounded-xl px-3 py-1.5 text-xl text-gray-500 transition-colors hover:bg-gray-800',
				isHighlighted && 'text-primary bg-gray-800',
			)}
			to={`/search?q=${encodeURIComponent(suggestion)}`}
			title={`Search for ${suggestion}`}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="mr-4 flex h-10 w-10 items-center justify-center rounded-full">
				{isHexColor ? (
					<div
						className="h-8 w-8 rounded-full border border-gray-500"
						style={{ backgroundColor: suggestion }}
					/>
				) : (
					<div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-900">
						<IconNavbarSearchSVG className="h-5 w-5" />
					</div>
				)}
			</div>
			{index === -1 ? (
				<span className="whitespace-pre">{suggestion}</span>
			) : (
				<div className="whitespace-pre">
					<span className={clsx('text-gray-500', isHighlighted && 'text-gray-300')}>
						{suggestion.substring(0, index)}
					</span>
					<span className={clsx('text-primary', isHighlighted && 'text-primary')}>
						{suggestion.substring(index, index + matchLength)}
					</span>
					<span className={clsx('text-gray-500', isHighlighted && 'text-gray-300')}>
						{suggestion.substring(index + matchLength)}
					</span>
				</div>
			)}
		</SVA>
	);
};

const _BackgroundOverlay = ({
	handleClick,
	isVisible,
}: {
	handleClick?: () => void;
	isVisible: boolean;
}) => (
	<div
		className={clsx(
			'duration-slide fixed inset-0 min-h-[130vh] bg-black bg-opacity-50 transition-opacity ease-in-out',
			isVisible ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
		)}
		onClick={(e) => {
			e.stopPropagation();
			handleClick?.();
		}}
	/>
);

const SearchSuggestionUser = ({
	suggestion,
	isSelected,
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHovered,
}: {
	suggestion: UserSuggestion;
	isSelected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	isHovered?: boolean;
}) => {
	// Apply styles based on selection or hover
	const isHighlighted = isSelected || isHovered;

	return (
		<SVA
			Component={SVLink}
			key={suggestion.username}
			className={clsx(
				'hover:text-primary flex w-full items-center justify-between rounded-xl px-3 py-2.5 text-gray-300 transition-colors hover:bg-gray-800',
				isHighlighted && 'text-primary bg-gray-800',
			)}
			to={suggestion.url}
			title={`View ${suggestion.name}'s profile`}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="flex items-center space-x-4">
				<SVImage
					src={suggestion.avatarURL}
					alt={suggestion.name}
					className="border-primary h-10 w-10 rounded-full border border-opacity-50"
				/>
				<div className="flex flex-col">
					<div className="text-base">
						{suggestion.name}
						{suggestion.isPro && <SVProBadge />}
					</div>
					<div className="text-sm text-gray-500">@{suggestion.username}</div>
				</div>
			</div>
		</SVA>
	);
};

const _NavSearchContainer = () => {
	const router = useRouter();
	const [searchInputQuery, setSearchInputQuery] = useUIState(UIStateKeys.SEARCH_INPUT_QUERY);
	const { q: queryParam, type: typeParam } = router.query;
	const [isOnFocus, setIsOnFocus] = useState(false);
	const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null);
	const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
	const searchTimeoutRef = useRef<number | undefined>(undefined);
	const [artificialLoading, setArtificialLoading] = useState(false);

	const currentType =
		typeof typeParam === 'string' &&
		SUPPORTED_TYPES.includes(typeParam as (typeof SUPPORTED_TYPES)[number])
			? (typeParam as (typeof SUPPORTED_TYPES)[number])
			: 'items';

	// Recent searches query
	const recentSearchesQuery = useQuery(RecentSearchesQuery);

	//  Popular queries
	const topSearchQueriesQuery = useQuery(TopSearchQueriesQuery);

	// Top colors query
	const topColorsQuery = useQuery(TopColorsQuery);

	// Top users query
	const topUsersQuery = useQuery(SuggestedUsersQuery);

	// Search suggestions query with debouncing
	const searchSuggestionsQuery = useQuery(SearchSuggestionsQuery, {
		variables: {
			query: debouncedSearchQuery || '',
		},
		skip: !Boolean(debouncedSearchQuery) || debouncedSearchQuery.length < MIN_QUERY_LENGTH,
		onCompleted: () => {
			setArtificialLoading(false);
		},
	});

	// Calculate if we should show loading state
	const isLoadingSuggestions = searchSuggestionsQuery.loading || artificialLoading;

	// Get suggestions for keyboard navigation
	const getUserSuggestions = () => {
		if (!searchSuggestionsQuery.data?.searchSuggestions || !debouncedSearchQuery) return [];
		return searchSuggestionsQuery.data.searchSuggestions.users.map((user) => ({
			...user,
			isUser: true as const,
		}));
	};

	const getTextSuggestions = () => {
		// Always include the current search query as the first suggestion if it meets minimum length
		if (searchInputQuery.trim().length === 0) return [];

		// If no suggestions from API yet but we're searching, return just the current query
		if (!searchSuggestionsQuery.data?.searchSuggestions) {
			return searchInputQuery.trim().length >= MIN_QUERY_LENGTH
				? [searchInputQuery.trim()]
				: [];
		}

		// Get suggestions from the API
		const suggestions = searchSuggestionsQuery.data.searchSuggestions.queries.map(
			(suggestion) => suggestion.query,
		);

		// Add the current query as first suggestion if it's not already in the list
		if (!suggestions.includes(searchInputQuery.trim())) {
			return [searchInputQuery.trim(), ...suggestions];
		}

		return suggestions;
	};

	const userSuggestions = getUserSuggestions();
	const textSuggestions = getTextSuggestions();
	const allSuggestions = [...textSuggestions, ...userSuggestions];

	// Calculate the maximum number of suggestions to show when loading,
	// this ensures keyboard navigation works correctly with skeletons
	const textSkeletonCount = 3;
	const userSkeletonCount = 2;
	const skeletonTotalCount =
		searchInputQuery.trim().length >= MIN_QUERY_LENGTH
			? 1 + textSkeletonCount + userSkeletonCount // 1 for current query + skeletons
			: 1; // Just the current query when length is less than minimum

	// Determine effective number of suggestions for keyboard navigation
	const allSuggestionsCount = searchSuggestionsQuery.loading
		? skeletonTotalCount
		: allSuggestions.length;

	useEffect(() => {
		if (router.pathname === '/search') {
			if (
				queryParam &&
				typeof queryParam === 'string' &&
				queryParam.length > MIN_QUERY_LENGTH
			) {
				setSearchInputQuery(queryParam);
				setIsOnFocus(false);
				return;
			}

			setIsOnFocus(true);
			return;
		}

		setSearchInputQuery('');
		setIsOnFocus(false);
	}, [queryParam, setSearchInputQuery, router]);

	useEffect(() => {
		// Reset selected index when input changes
		setSelectedIndex(-1);
		setHoveredItemIndex(null);

		// Set up debounce for search query
		if (searchTimeoutRef.current !== undefined) {
			window.clearTimeout(searchTimeoutRef.current);
		}

		const hasNonEmptyQuery = searchInputQuery.trim().length > 0;

		// Show loading state immediately when typing any non-empty query
		if (hasNonEmptyQuery) {
			setArtificialLoading(true);

			// Only send to API if query meets minimum length
			if (searchInputQuery.trim().length >= MIN_QUERY_LENGTH) {
				// @ts-ignore - window.setTimeout returns number in browser
				searchTimeoutRef.current = window.setTimeout(() => {
					setDebouncedSearchQuery(searchInputQuery.trim());
					searchTimeoutRef.current = undefined;
				}, 300);
			} else {
				// For short queries, clear debounced query but keep the loading state briefly
				setDebouncedSearchQuery('');

				// Clear artificial loading after a short delay to show loading feedback
				// @ts-ignore - window.setTimeout returns number in browser
				searchTimeoutRef.current = window.setTimeout(() => {
					setArtificialLoading(false);
					searchTimeoutRef.current = undefined;
				}, 500);
			}
		} else {
			setDebouncedSearchQuery('');
			setArtificialLoading(false);
		}

		return () => {
			if (searchTimeoutRef.current !== undefined) {
				window.clearTimeout(searchTimeoutRef.current);
				searchTimeoutRef.current = undefined;
			}
		};
	}, [searchInputQuery]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setIsOnFocus(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleResetSearch = useEventCallback(() => {
		setIsOnFocus(false);
		setIsColorPickerOpen(false);
		setSearchInputQuery('');
		// Force blur on input
		setTimeout(() => {
			const inputElement = document.querySelector('.search-input-field') as HTMLInputElement;
			if (inputElement) {
				inputElement.blur();
			}
		}, 0);
	});

	// Add scroll event listener to close search when scrolling down
	useEffect(() => {
		// Store initial scroll position when search is opened
		const initialScrollY = window.scrollY;

		const handleScroll = () => {
			const currentScrollY = window.scrollY;

			// Close search if it's open and user has scrolled down past the tolerance threshold
			if (
				(isOnFocus || isColorPickerOpen) &&
				Math.abs(currentScrollY - initialScrollY) > SCROLL_TOLERANCE
			) {
				handleResetSearch();
			}
		};

		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => window.removeEventListener('scroll', handleScroll);
	}, [isOnFocus, isColorPickerOpen, handleResetSearch]);

	const handleSearchChange = useEventCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputQuery(e.target.value);
	});

	const navigateToSearch = useEventCallback((query: string) => {
		if (query.trim().length > MIN_QUERY_LENGTH) {
			setSearchInputQuery(query.trim());
			setDebouncedSearchQuery(query.trim());
			setIsOnFocus(false);

			router.replace(
				{
					pathname: '/search',
					query: {
						q: query.trim(),
						...(currentType !== 'items' && { type: currentType }),
					},
				},
				undefined,
				{ shallow: true },
			);
		} else if (router.pathname === '/search') {
			router.replace({
				pathname: '/search',
			});
		}
	});

	const handleSelectSuggestion = useEventCallback((suggestion: string | UserSuggestion) => {
		setIsOnFocus(false);
		if (typeof suggestion === 'string') {
			navigateToSearch(suggestion);
		} else {
			// Navigate to user profile
			router.push(suggestion.url);
		}
	});

	const handleKeyDown = useEventCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		// Keyboard navigation logic
		if (allSuggestionsCount > 0 && isOnFocus) {
			switch (e.key) {
				case 'ArrowDown':
					e.preventDefault();
					setSelectedIndex((prev) => (prev < allSuggestionsCount - 1 ? prev + 1 : 0));
					setHoveredItemIndex(null);
					break;
				case 'ArrowUp':
					e.preventDefault();
					setSelectedIndex((prev) => (prev > 0 ? prev - 1 : allSuggestionsCount - 1));
					setHoveredItemIndex(null);
					break;
				case 'Tab':
					e.preventDefault();
					if (selectedIndex >= 0 && selectedIndex < allSuggestionsCount) {
						// If we're selecting a skeleton, default to the current query
						if (searchSuggestionsQuery.loading && selectedIndex > 0) {
							handleSelectSuggestion(searchInputQuery.trim());
						} else if (!searchSuggestionsQuery.loading) {
							// Normal selection logic when not loading
							if (selectedIndex < allSuggestions.length) {
								handleSelectSuggestion(allSuggestions[selectedIndex]);
							}
						}
					}
					break;
				case 'Enter':
					e.preventDefault();
					if (selectedIndex >= 0 && selectedIndex < allSuggestionsCount) {
						// If we're selecting a skeleton, default to the current query
						if (searchSuggestionsQuery.loading && selectedIndex > 0) {
							handleSelectSuggestion(searchInputQuery.trim());
						} else if (!searchSuggestionsQuery.loading) {
							// Normal selection logic when not loading
							if (selectedIndex < allSuggestions.length) {
								handleSelectSuggestion(allSuggestions[selectedIndex]);
							}
						}
					} else {
						// Cancel any pending debounce timeout
						if (searchTimeoutRef.current !== undefined) {
							window.clearTimeout(searchTimeoutRef.current);
						}
						navigateToSearch(searchInputQuery);
					}
					break;
				case 'Escape':
					e.preventDefault();
					setIsOnFocus(false);
					break;
			}
		} else if (e.key === 'Enter') {
			e.preventDefault();
			// Cancel any pending debounce timeout
			if (searchTimeoutRef.current !== undefined) {
				window.clearTimeout(searchTimeoutRef.current);
			}
			navigateToSearch(searchInputQuery);
		}
	});

	const handleClearSearch = useEventCallback((e: React.UIEvent) => {
		if (e) {
			e.stopPropagation();
		}

		setSearchInputQuery('');

		if (router.pathname === '/search') {
			router.replace({
				pathname: '/explore',
				query: undefined,
			});
			return;
		}

		setIsOnFocus(false);
	});

	const handleContainerClick = useEventCallback(() => {
		if (isColorPickerOpen) {
			setIsColorPickerOpen(false);
		}
		setIsOnFocus(true);
	});

	const handleItemHover = useEventCallback((index: number | null) => {
		setHoveredItemIndex(index);
	});

	const handleColorPickerOpenChange = useEventCallback((isOpen: boolean) => {
		setIsColorPickerOpen(isOpen);
	});

	const handleOverlayClick = useEventCallback((e?: React.SyntheticEvent) => {
		if (e) {
			e.stopPropagation();
		}

		setIsOnFocus(false);
		setIsColorPickerOpen(false);

		document.activeElement instanceof HTMLElement && document.activeElement.blur();
	});

	// Only show content when we're focused AND the color picker is closed
	const shouldShowContent = isOnFocus && !isColorPickerOpen;

	return (
		<>
			<div
				ref={containerRef}
				onClick={handleContainerClick}
				className="-sm:hidden relative z-20"
			>
				<_Input
					value={searchInputQuery}
					onChange={handleSearchChange}
					onKeyDown={handleKeyDown}
					isOnFocus={isOnFocus}
					onClear={(e) => handleClearSearch(e)}
					onFocus={() => setIsOnFocus(true)}
					onSelectSuggestion={(suggestion) => handleSelectSuggestion(suggestion)}
					onColorPickerOpen={handleColorPickerOpenChange}
					isColorPickerOpen={isColorPickerOpen}
				/>

				<_SearchContent
					recentSearches={recentSearchesQuery.data?.recentSearches || []}
					popularSearches={topSearchQueriesQuery.data?.topSearchQueries || []}
					isVisible={shouldShowContent}
					searchQuery={searchInputQuery}
					selectedIndex={selectedIndex}
					onSelectSuggestion={handleSelectSuggestion}
					onItemHover={handleItemHover}
					hoveredItemIndex={hoveredItemIndex}
					searchSuggestions={searchSuggestionsQuery.data?.searchSuggestions}
					topColors={topColorsQuery.data?.topColors}
					topUsers={topUsersQuery.data?.suggestedUsers}
					isLoadingSuggestions={isLoadingSuggestions}
				/>
				<_BackgroundOverlay
					handleClick={handleOverlayClick}
					isVisible={isColorPickerOpen || isOnFocus}
				/>
			</div>
			<SVKeyboardKey keys={['escape']} onTrigger={handleResetSearch} />
		</>
	);
};

const SVNavSearchContainer = () => {
	const isLoggedIn = useIsLoggedIn();

	if (!isLoggedIn) {
		return null;
	}

	return <_NavSearchContainer />;
};

export default SVNavSearchContainer;
